import React, { Key, useCallback, useMemo } from "react";
import {
  AppstoreAddOutlined,
  BgColorsOutlined,
  PlusOutlined,
  LoadingOutlined,
  HomeOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Divider, Dropdown, MenuProps, Spin, Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { useCollectionContext } from "../contexts/CollectionContext";
import { CollectionModelType, PartModelType, MixType } from "../types";
import { useMeshMaterials } from "../hooks";
import { useUIContext } from "../contexts/UIContext";

const LabelContextMenu = ({
  item,
  part,
  moveItem,
  children,
}: {
  item: MixType;
  part?: PartModelType;
  moveItem: (direction: number, item: MixType) => void;
  children: React.ReactElement;
}) => {
  const { getItemByUUID } = useCollectionContext();
  const { clipboardItem, setClipboardItem } = useUIContext();
  const { cloneMeshMaterials } = useMeshMaterials();
  const menuItems = useMemo(() => {
    const items: MenuProps["items"] = [];
    if (part) {
      items.push({
        label: <span>Copy all materials</span>,
        key: "copy-materials",
        onClick: (e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          const { _uuid } = part;
          const item = getItemByUUID(_uuid);
          setTimeout(() => {
            if (item) setClipboardItem(item);
          }, 500);
        },
      });
      if (clipboardItem && clipboardItem.data.part) {
        items.push({
          label: (
            <span>
              Paste materials from <i>{clipboardItem.data.part?.name}"</i>
            </span>
          ),
          key: "paste-materials",
          onClick: async (e) => {
            e.domEvent.stopPropagation();
            const { part: originPart } = clipboardItem.data;
            if (originPart) {
              await cloneMeshMaterials({
                //originPart,
                destinationPart: part,
                meshMaterials: originPart.childrens,
                selectedItem: clipboardItem,
              });
              setClipboardItem(null);
            }
          },
        });
      }
    }
    items.push({
      label: "Move Up",
      key: "move-up",
      icon: <UpOutlined />,
      onClick: () => moveItem(-1, item),
    });

    items.push({
      label: "Move Down",
      key: "move-down",
      icon: <DownOutlined />,
      onClick: () => moveItem(1, item),
    });

    return items;
  }, [
    clipboardItem,
    cloneMeshMaterials,
    getItemByUUID,
    part,
    item,
    setClipboardItem,
    moveItem,
  ]);

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["contextMenu"]}
      overlayClassName="layouts-explorer-context-menu"
    >
      {children}
    </Dropdown>
  );
};

interface CollectionExplorerProps {
  collection: CollectionModelType | null;
  onSelect?: (selectedKey: string) => void;
  selectedKey?: string;
  onCreateAssociation?: () => void;
  disableCreateAssociation?: boolean;
}

export const CollectionExplorer = ({
  collection,
  onSelect = () => {},
  selectedKey,
  onCreateAssociation = () => {},
  disableCreateAssociation = false,
}: CollectionExplorerProps) => {
  const { moveItemByUUID } = useCollectionContext();
  const { isLoading } = useUIContext();
  const moveItem = useCallback(
    (direction: number, item: MixType) => moveItemByUUID(item._uuid, direction),
    [moveItemByUUID]
  );
  const treeData: DataNode[] = useMemo(() => {
    if (collection) {
      return [collection].map((collectionItem) => ({
        key: collectionItem._uuid,
        icon: <HomeOutlined />,
        title: (
          // Collection level doesn't need move buttons, assuming it's the topmost level
          <LabelContextMenu item={collectionItem} moveItem={moveItem}>
            <span>
              <b>Collection: </b>
              {collectionItem.name}
            </span>
          </LabelContextMenu>
        ),
        children: collectionItem.childrens.map((item) => ({
          key: item._uuid,
          title: (
            <div>
              <LabelContextMenu item={item} moveItem={moveItem}>
                <span>
                  <b>Item: </b>
                  {item.name}
                </span>
              </LabelContextMenu>
              <Button
                size="small"
                icon={<UpOutlined />}
                onClick={() => moveItem(-1, item)}
              />
              <Button
                size="small"
                icon={<DownOutlined />}
                onClick={() => moveItem(1, item)}
              />
            </div>
          ),
          children: item.childrens.map((itemVersion) => ({
            key: itemVersion._uuid,
            title: (
              <div>
                <LabelContextMenu item={itemVersion} moveItem={moveItem}>
                  <span>
                    <b>Version: </b>
                    {itemVersion.name}
                  </span>
                </LabelContextMenu>
                <Button
                  size="small"
                  icon={<UpOutlined />}
                  onClick={() => moveItem(-1, itemVersion)}
                />
                <Button
                  size="small"
                  icon={<DownOutlined />}
                  onClick={() => moveItem(1, itemVersion)}
                />
              </div>
            ),
            children: itemVersion.childrens.map((partGroup) => ({
              key: partGroup._uuid,
              title: (
                <div>
                  <LabelContextMenu item={partGroup} moveItem={moveItem}>
                    <span>
                      <b>Part Group: </b>
                      {partGroup.name}
                    </span>
                  </LabelContextMenu>
                  <Button
                    size="small"
                    icon={<UpOutlined />}
                    onClick={() => moveItem(-1, partGroup)}
                  />
                  <Button
                    size="small"
                    icon={<DownOutlined />}
                    onClick={() => moveItem(1, partGroup)}
                  />
                </div>
              ),
              icon: <AppstoreAddOutlined />,
              children: partGroup.childrens.map((part) => ({
                key: part._uuid,
                title: (
                  <div>
                    <LabelContextMenu
                      item={part}
                      part={part}
                      moveItem={moveItem}
                    >
                      <span>
                        <b>Part: </b>
                        {part.name}
                      </span>
                    </LabelContextMenu>
                    <Button
                      size="small"
                      icon={<UpOutlined />}
                      onClick={() => moveItem(-1, part)}
                    />
                    <Button
                      size="small"
                      icon={<DownOutlined />}
                      onClick={() => moveItem(1, part)}
                    />
                  </div>
                ),
                children: part.childrens.map((meshMaterial) => ({
                  key: meshMaterial._uuid,
                  title: (
                    <div>
                      <LabelContextMenu item={meshMaterial} moveItem={moveItem}>
                        <span>
                          <b>Material: </b>
                          {meshMaterial.name}
                        </span>
                      </LabelContextMenu>
                      <Button
                        size="small"
                        icon={<UpOutlined />}
                        onClick={() => moveItem(-1, meshMaterial)}
                      />
                      <Button
                        size="small"
                        icon={<DownOutlined />}
                        onClick={() => moveItem(1, meshMaterial)}
                      />
                    </div>
                  ),
                  icon: <BgColorsOutlined />,
                  isLeaf: true,
                })),
              })),
            })),
          })),
        })),
      }));
    }
    return [];
  }, [collection, moveItem]);

  const handleOnSelect = useCallback(
    (keys: Key[], info: any) => {
      onSelect((keys[0] as string) || "");
    },
    [onSelect]
  );

  return (
    <div className="explorer">
      <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
        <h3 className="title">
          <span>ITEMS COLLECTION</span>
        </h3>
      </Spin>
      {!!treeData.length && (
        <Tree
          showLine={true}
          defaultExpandedKeys={[treeData[0].key]}
          //defaultExpandAll
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          showIcon={true}
          onSelect={handleOnSelect}
          treeData={treeData}
        />
      )}
      {!disableCreateAssociation && (
        <div className="footer">
          <Divider />
          <Button
            onClick={() => {
              onCreateAssociation();
            }}
          >
            <PlusOutlined /> View/Edit Associations
          </Button>
        </div>
      )}
    </div>
  );
};
