import { format } from "date-fns";
import { eachParallel } from "utils";
import { ProductsService } from "servicesNew/api";
import ApiService from "services/ApiService";
import {
  getCollectionModel,
  getItemModel,
  getItemVersionModel,
  getPartGroupModel,
  getPartModel,
  getMeshMaterialModel,
} from "./dataModel";

import { Item } from "../../../../servicesNew/api/models/Item";
import { ItemVersion } from "../../../../servicesNew/api/models/ItemVersion";
import { PartGroup } from "../../../../servicesNew/api/models/PartGroup";
import { Part } from "../../../../servicesNew/api/models/Part";
import { MeshMaterial } from "../../../../servicesNew/api/models/MeshMaterial";
import list from "modules/product/screens/list";
import { PartModelType } from "../types";
import { PartGroupModelType } from "../types";
import { useScene } from "react-babylonjs";
import { useState } from "react";
// Utility function to delay execution for a given number of milliseconds
const delay = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

// General-purpose function to retry any promise-returning function (like an API call)
// 'fn' is a function that returns a promise, and it doesn't take any arguments.
const withRetry = async <T>(
  fn: () => Promise<T>,
  maxRetries = 3,
  retryDelay = 1000
): Promise<T> => {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await fn();
    } catch (error) {
      if (attempt === maxRetries) throw error;
      await delay(retryDelay);
      // Optionally, increase delay with each retry or implement other backoff strategies
    }
  }
  // This line is just to satisfy TypeScript, but it should never actually be reached due to the throw above
  throw new Error("withRetry reached an unexpected point");
};
// const [itemVersion, setItemVersion] = useState();
// Now, you can use these functions in your fetchAll function without TypeScript errors.

//UPDATED FUNCTION

export const fetchAll = async (collectionId: number) => {
  // const itemCollection = await withRetry(() =>
  //   ProductsService.productsItemCollectionsRead({
  //     id: collectionId,
  //   })
  // );
  // const collection = getCollectionModel(itemCollection);
  // console.log("itemCollection", itemCollection);

  // Fetch items and sort them by list_order, using 0 as fallback for missing list_order
  const itemsResponse: any = await withRetry(() =>
    ProductsService.productsItemsList({
      itemCollection: `${collectionId}`,
    })
  );

  const collection = getCollectionModel(itemsResponse?.itemsResponse);
  const sortedItems = itemsResponse?.results?.sort(
    (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
  );

  await eachParallel(sortedItems, async (itemResponse: Item) => {
    const item = getItemModel(itemResponse);
    collection.childrens.push(item);

    //This is the api call to item_veriosns to show the item verison and also send the data to the partGroups
    const itemVersions: any = await withRetry(() =>
      ProductsService.productsPartGroupsList({
        //this has to call
        itemVersion: `${item?.current_version_id}`,
      })
    );

    const results: any = [];
    results.push(itemVersions?.partGroups);

    // return;
    // if (itemVersions.versions) {
    //   results = itemVersions.versions.map(getItemVersionModel);
    // }
    // Fetch item versions and sort them by list_order, using 0 as fallback
    // const itemVersions: ItemVersion[] = await withRetry(() =>
    //   ProductsService.productsItemVersionsList({
    //     item: `${itemResponse.id}`,
    //   })
    // );
    // console.log("itemVersions data", itemVersions);
    const sortedItemVersions = results.sort(
      (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
    );

    // let itemVersionsData: any = [];

    await eachParallel(
      sortedItemVersions,
      async (itemVersionResponse: ItemVersion) => {
        const itemVersion = getItemVersionModel(itemVersionResponse);
        const extra_price_modifier =
          itemVersionResponse.extra_price_modifier || [];
        item.childrens.push(itemVersion);

        // itemVersionsData.push(itemVersion);
        // console.log("itemVersionsData", itemVersionsData);
        // Fetch part groups and sort them by list_order, using 0 as fallback
        //The partGroups return both the modified data as before with a key results and also return the whole api response with key partGroups which can be use in future
        // const partGroups: any = await withRetry(() =>
        //   ProductsService.productsPartGroupsList({
        //     //this has to call
        //     itemVersion: `${itemVersionResponse.id}`,
        //   })
        // );
        // console.log("partGroupsFetch", partGroups);

        const sortedPartGroups = itemVersionResponse?.part_groups.sort(
          (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
        );

        await eachParallel(
          sortedPartGroups,
          async (partGroupResponse: PartGroup) => {
            const partGroup = getPartGroupModel(partGroupResponse);
            itemVersion.childrens.push(partGroup);

            // Fetch parts and sort them by list_order, using 0 as fallback
            // const parts: Part[] = await withRetry(() =>
            //   ProductsService.productsPartsList({
            //     partGroup: `${partGroupResponse.id}`,
            //   })
            // );

            const sortedParts = partGroup?.parts.sort(
              (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
            );

            await eachParallel(sortedParts, async (partResponse: Part) => {
              const part = getPartModel(partResponse);
              const part_association_groups =
                partResponse.part_association_groups || [];
              partGroup.childrens.push(part);

              // Fetch mesh materials and sort them by list_order, using 0 as fallback
              // const meshMaterials: MeshMaterial[] = await withRetry(() =>
              //   ProductsService.productsMeshMaterialsList({
              //     part: `${partResponse.id}`,
              //   })
              // );
              const sortedMeshMaterials = part?.materials.sort(
                (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
              );

              await eachParallel(
                sortedMeshMaterials,
                async (meshMaterial: MeshMaterial) => {
                  let price_mod = 0;
                  let materialPriceModOverrideDefault = 0;
                  if (
                    part.id !== undefined &&
                    meshMaterial.id !== undefined
                    //extra_price_modifier[part.id] &&
                    //extra_price_modifier[part.id][meshMaterial.id]
                  ) {
                    price_mod = extra_price_modifier[part.id][meshMaterial.id];
                    materialPriceModOverrideDefault = meshMaterial.price_mod;
                  }
                  const newMeshMaterial = getMeshMaterialModel(
                    meshMaterial,
                    price_mod
                    //materialPriceModOverrideDefault
                  );
                  const material_associations =
                    meshMaterial.material_associations || [];
                  //part.childrens.push(newMeshMaterial);
                  const categories =
                    meshMaterial.material_category_associations || [];
                  part.childrens.push(newMeshMaterial);
                }
              );
            });
          }
        );
      }
    );
  });

  return { collection };
};
// OLD FUNCTION

// export const fetchAll = async (collectionId: number) => {
//   const itemCollection = await withRetry(() =>
//     ProductsService.productsItemCollectionsRead({
//       id: collectionId,
//     })
//   );
//   const collection = getCollectionModel(itemCollection);

//   // Fetch items and sort them by list_order, using 0 as fallback for missing list_order
//   const itemsResponse: Item[] = await withRetry(() =>
//     ProductsService.productsItemsList({
//       itemCollection: `${itemCollection.id}`,
//     })
//   );
//   const sortedItems = itemsResponse.sort(
//     (a, b) => (a.list_order || 0) - (b.list_order || 0)
//   );

//   await eachParallel(sortedItems, async (itemResponse: Item) => {
//     const item = getItemModel(itemResponse);
//     collection.childrens.push(item);

//     // Fetch item versions and sort them by list_order, using 0 as fallback
//     const itemVersions: ItemVersion[] = await withRetry(() =>
//       ProductsService.productsItemVersionsList({
//         item: `${itemResponse.id}`,
//       })
//     );
//     const sortedItemVersions = itemVersions.sort(
//       (a, b) => (a.list_order || 0) - (b.list_order || 0)
//     );

//     await eachParallel(
//       sortedItemVersions,
//       async (itemVersionResponse: ItemVersion) => {
//         const itemVersion = getItemVersionModel(itemVersionResponse);
//         const extra_price_modifier =
//           itemVersionResponse.extra_price_modifier || [];
//         item.childrens.push(itemVersion);

//         // Fetch part groups and sort them by list_order, using 0 as fallback
//         const partGroups: PartGroup[] = await withRetry(() =>
//           ProductsService.productsPartGroupsList({
//             itemVersion: `${itemVersionResponse.id}`,
//           })
//         );
//         const sortedPartGroups = partGroups.sort(
//           (a, b) => (a.list_order || 0) - (b.list_order || 0)
//         );

//         // return;
//         await eachParallel(
//           sortedPartGroups,
//           async (partGroupResponse: PartGroup) => {
//             const partGroup = getPartGroupModel(partGroupResponse);
//             itemVersion.childrens.push(partGroup);

//             // Fetch parts and sort them by list_order, using 0 as fallback
//             const parts: Part[] = await withRetry(() =>
//               ProductsService.productsPartsList({
//                 partGroup: `${partGroupResponse.id}`,
//               })
//             );
//             console.log("parts", parts);
//             const sortedParts = parts.sort(
//               (a, b) => (a.list_order || 0) - (b.list_order || 0)
//             );

//             await eachParallel(sortedParts, async (partResponse: Part) => {
//               const part = getPartModel(partResponse);
//               const part_association_groups =
//                 partResponse.part_association_groups || [];
//               partGroup.childrens.push(part);

//               // Fetch mesh materials and sort them by list_order, using 0 as fallback
//               const meshMaterials: MeshMaterial[] = await withRetry(() =>
//                 ProductsService.productsMeshMaterialsList({
//                   part: `${partResponse.id}`,
//                 })
//               );
//               const sortedMeshMaterials = meshMaterials.sort(
//                 (a, b) => (a.list_order || 0) - (b.list_order || 0)
//               );

//               await eachParallel(
//                 sortedMeshMaterials,
//                 async (meshMaterial: MeshMaterial) => {
//                   let price_mod = 0;
//                   if (
//                     part.id !== undefined &&
//                     meshMaterial.id !== undefined &&
//                     extra_price_modifier[part.id] &&
//                     extra_price_modifier[part.id][meshMaterial.id]
//                   ) {
//                     price_mod = extra_price_modifier[part.id][meshMaterial.id];
//                   }
//                   const newMeshMaterial = getMeshMaterialModel(
//                     meshMaterial,
//                     price_mod
//                   );
//                   const material_associations =
//                     meshMaterial.material_associations || [];
//                   //part.childrens.push(newMeshMaterial);
//                   const categories =
//                     meshMaterial.material_category_associations || [];
//                   part.childrens.push(newMeshMaterial);
//                 }
//               );
//             });
//           }
//         );
//       }
//     );
//   });

//   return { collection };
// };

export const createItemCollectionTree = async ({
  companyId,
  collectionName,
  itemName,
}: {
  companyId: number;
  collectionName: string;
  itemName: string;
}) => {
  const itemCollection = await ProductsService.productsItemCollectionsCreate({
    data: {
      company: companyId,
      name: collectionName,
    },
  });

  const itemCollectionTree = getCollectionModel(itemCollection);
  const itemTree = await createItemTree({
    name: itemName,
    collectionId: itemCollection.id,
    order_id: 0,
    list_order: 0,
  });
  itemCollectionTree.childrens = [itemTree];
  return itemCollectionTree;
};

export const createItemTree = async ({
  collectionId,
  name,
  order_id,
  list_order,
  uniqueSuffix,
  dimensions,
  description,
}: {
  collectionId: number;
  name: string;
  order_id: number;
  list_order: number;
  uniqueSuffix?: string;
  dimensions?: any;
  description?: string;
}) => {
  const suffix = uniqueSuffix
    ? uniqueSuffix
    : format(new Date(), "yyyy-MM-dd HH-mm:ss");
  const item = await ProductsService.productsItemsCreate({
    data: {
      item_collection_id: collectionId,
      name: `${name} -- ${suffix}`,
      order_id,
      list_order,
      dimensions,
      description,
    },
  });

  // where create itemVersion function starts... should get rid of this code because we don't want to POST an itemVersion because it was already POSTED in the createItem code above
  const itemTree = getItemModel(item);

  const itemVersionTree = await createItemVersionTree({
    itemVersionId: item.current_version_id,
  });
  itemTree.childrens = [itemVersionTree];
  return itemTree;
};

export const createItemVersionTree = async ({
  itemVersionId,
}: {
  itemVersionId: number;
}) => {
  const itemVersion = await ProductsService.productsItemVersionsRead({
    id: itemVersionId,
  });

  // where partgroup function starts
  const partGroupTree = await createPartGroupTree({
    itemVersionId: itemVersion.id,
    name: "Base",
    list_order: 0,
  });

  // Reconcile
  const itemVersionTree = getItemVersionModel(itemVersion);
  itemVersionTree.childrens = [partGroupTree];
  return itemVersionTree;
};

export const createPartGroupTree = async ({
  itemVersionId,
  name,
  list_order,
}: {
  itemVersionId: number;
  name: string;
  list_order?: number;
}) => {
  const partGroup = await ProductsService.productsPartGroupsCreate({
    data: { item_version_id: itemVersionId, name, list_order: list_order },
  });

  const partModel = await createPart({
    partGroupId: partGroup.id,
    partGroupName: name,
    name: "Standard Base",
    list_order: 0,
  });

  // Reconcile
  const partGroupTree = getPartGroupModel(partGroup);
  partGroupTree.childrens = [partModel];
  return partGroupTree;
};

export const createPart = async ({
  partGroupId,
  partGroupName,
  name,
  list_order,
}: {
  partGroupId: number;
  partGroupName: string;
  name: string;
  list_order: number;
}) => {
  const part = await ProductsService.productsPartsCreate({
    data: {
      name: name,
      part_group_id: partGroupId,
      price_mod: 0,
      list_order: list_order,
    },
  });

  // Reconcile
  return getPartModel(part);
};

export const uploadFileToS3 = async (
  fileData: Blob,
  url: string,
  fields: { [key: string]: any }
) => {
  try {
    const uploadFormData = new FormData();
    Object.keys(fields).forEach((key) => {
      uploadFormData.append(key, fields[key]);
    });
    uploadFormData.append("file", fileData);
    // @ts-ignore
    const s3UploadResponse = await ApiService.postToS3(url, uploadFormData);
    return s3UploadResponse;
  } catch (error) {
    console.warn(error);
  }
};

export const postReferenceFiles = async ({
  id,
  key,
  file_name,
  fileData,
}: {
  id: number;
  key: string;
  file_name: string;
  fileData: Blob;
}) => {
  const s3UrlResponse = await ApiService.postFileLocker(id, {
    key,
    file_name,
  });
  const s3UrlData = s3UrlResponse.data;
  const { url, fields } = s3UrlData.url;
  await uploadFileToS3(fileData, url, fields);
  return s3UrlResponse;
};
