import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import {
  SelectedItem,
  MixType,
  MixTypePartial,
  CollectionModelType,
} from "../types";
import { ProductsService } from "servicesNew/api";

const collectionWalker = (
  iterable: any[],
  callback: (item: MixType, parent: MixType | null) => void | boolean,
  parent: MixType | null = null
) => {
  for (const i of iterable) {
    const response = callback(i as MixType, parent);
    if (response) return;
    const newIterable: any[] = i.childrens || [];
    if (newIterable.length) {
      collectionWalker(newIterable, callback, i);
    }
  }
};

interface CollectionContextValue {
  companyId: number;
  orderId: number;
  itemCollectionId: number | null;
  collection: CollectionModelType | null;
  setCollection: (value: React.SetStateAction<CollectionModelType>) => void;
  selectedKey: string;
  setSelectedKey: (value: React.SetStateAction<string>) => void;
  selectedItem: SelectedItem | null;
  getItemByUUID: (uuid: string) => SelectedItem | null;
  updateItemByUUID: (
    uuid: string,
    callback: (item: MixType, parent: MixType | null) => MixTypePartial
  ) => void;
  moveItemByUUID: (uuid: string, direction: number) => void;
}

const CollectionContext = createContext<CollectionContextValue>({
  companyId: 0,
  orderId: 0,
  itemCollectionId: null,
  collection: null,
  setCollection: () => {},
  selectedKey: "",
  setSelectedKey: () => {},
  selectedItem: null,
  getItemByUUID: () => null,
  updateItemByUUID: () => {},
  moveItemByUUID: () => {},
});

export const CollectionContextProvider = (props: any) => {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionModelType | null>(
    null
  );
  const [selectedKey, setSelectedKey] = useState<string>("");
  const { companyId, orderId, itemCollectionId } = useMemo(
    () => ({
      companyId: Number(params.companyId),
      orderId: Number(params.orderId),
      itemCollectionId: Number(params.itemCollectionId) || null,
    }),
    [params.companyId, params.itemCollectionId, params.orderId]
  );
  const reverseMapByUUID = useMemo(() => {
    const mapped: { [key: string]: SelectedItem } = {};
    if (collection) {
      mapped[collection._uuid] = {
        _uuid: collection._uuid,
        id: collection.id,
        type: "collection",
        name: collection.name,
        item: collection,
        data: { collection },
      };
      collection.childrens.forEach((item) => {
        mapped[item._uuid] = {
          _uuid: item._uuid,
          id: item.id,
          type: "item",
          name: item.name,
          item: item,
          data: { collection, item },
        };
        item.childrens.forEach((itemVersion) => {
          mapped[itemVersion._uuid] = {
            _uuid: itemVersion._uuid,
            id: itemVersion.id,
            type: "itemVersion",
            name: itemVersion.name,
            item: itemVersion,
            data: { collection, item, itemVersion },
          };
          itemVersion.childrens.forEach((partGroup) => {
            mapped[partGroup._uuid] = {
              _uuid: partGroup._uuid,
              id: partGroup.id,
              type: "partGroup",
              name: partGroup.name,
              item: partGroup,
              data: { collection, item, itemVersion, partGroup },
            };
            partGroup.childrens.forEach((part) => {
              mapped[part._uuid] = {
                _uuid: part._uuid,
                id: part.id,
                type: "part",
                name: part.name,
                item: part,
                data: { collection, item, itemVersion, partGroup, part },
              };
              part.childrens.forEach((meshMaterial) => {
                mapped[meshMaterial._uuid] = {
                  _uuid: meshMaterial._uuid,
                  id: meshMaterial.id,
                  type: "meshMaterial",
                  name: meshMaterial.name,
                  item: meshMaterial,
                  data: {
                    collection,
                    item,
                    itemVersion,
                    partGroup,
                    part,
                    meshMaterial,
                  },
                };
              });
            });
          });
        });
      });
    }
    return mapped;
  }, [collection]);
  const selectedItem = useMemo<SelectedItem | null>(
    () => reverseMapByUUID[selectedKey] || null,
    [reverseMapByUUID, selectedKey]
  );

  const getItemByUUID = useCallback(
    (uuid: string) => reverseMapByUUID[uuid],
    [reverseMapByUUID]
  );
  const updateItemByUUID = useCallback(
    (
      uuid: string,
      callback: (item: MixType, parent: MixType | null) => MixTypePartial
    ): void => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfCollection: [CollectionModelType] = [
          JSON.parse(JSON.stringify(collection)),
        ];
        collectionWalker(copyOfCollection, (_item, _parent) => {
          if (_item._uuid === uuid) {
            Object.assign(_item, callback(_item, _parent));
            return true;
          }
        });
        setCollection(copyOfCollection[0]);
      }
    },
    [collection, getItemByUUID]
  );

  // Adjusting the type guard to check if an item has childrens property
  function hasChildrens(item: any): item is MixType & { childrens: MixType[] } {
    return Array.isArray(item.childrens);
  }

  const moveItemByUUID = useCallback(
    (uuid: string, direction: number): void => {
      console.log("Move action on item:", selectedItem);
      if (!selectedItem || !collection) return;
      let targetIndex = -1;
      let parentArray: (MixType & { childrens: MixType[] })[] | undefined =
        undefined;
      const copyOfCollection: [CollectionModelType] = [
        JSON.parse(JSON.stringify(collection)),
      ];
      collectionWalker(copyOfCollection, (item, parent) => {
        if (parent && hasChildrens(parent)) {
          const index = parent.childrens.findIndex(
            (child) => child._uuid === uuid
          );
          if (index !== -1) {
            targetIndex = index;
            parentArray = parent.childrens as (MixType & {
              childrens: MixType[];
            })[];
            return;
          }
        }
      });
      if (parentArray && targetIndex >= 0) {
        const siblingIndex = targetIndex + direction;
        const confirmedParentArray = parentArray as (MixType & {
          childrens: MixType[];
        })[];
        if (siblingIndex >= 0 && siblingIndex < confirmedParentArray.length) {
          const [reorderedItem] = confirmedParentArray.splice(targetIndex, 1);
          confirmedParentArray.splice(siblingIndex, 0, reorderedItem);
          setCollection(copyOfCollection[0]);
          const updateOrder = (item: MixType, newOrder: number) => {
            if (selectedItem.type) {
              console.log(`Updating ${selectedItem.type} order to ${newOrder}`);
              switch (selectedItem.type) {
                case "meshMaterial":
                  ProductsService.productsMeshMaterialsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "item":
                  ProductsService.productsItemsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "itemVersion":
                  ProductsService.productsItemVersionsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "partGroup":
                  ProductsService.productsPartGroupsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "part":
                  ProductsService.productsPartsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                default:
                  console.error(
                    `No update logic implemented for type: ${selectedItem.type}`
                  );
              }
            }
          };
          if (selectedItem.type) {
            updateOrder(reorderedItem, siblingIndex);
            // Correctly calculating the adjacent item's index based on the direction
            let adjacentItemIndex;
            if (direction > 0) {
              // Moving down
              adjacentItemIndex = siblingIndex - 1;
            } else {
              // Moving up
              adjacentItemIndex = siblingIndex + 1;
            }
            const adjacentItem = confirmedParentArray[adjacentItemIndex];
            if (adjacentItem) {
              // When moving down, the reordered item takes the new position, and the adjacent item's position should be updated to the target index
              // When moving up, the reordered item's new order is siblingIndex, and the adjacent (previously above, now below) item's order is updated to targetIndex
              updateOrder(
                adjacentItem,
                direction > 0 ? targetIndex : adjacentItemIndex
              );
            }
          }
        }
      }
    },
    [collection, setCollection, selectedItem]
  );

  const value = {
    companyId,
    orderId,
    itemCollectionId,
    collection,
    setCollection,
    selectedKey,
    setSelectedKey,
    selectedItem,
    getItemByUUID,
    updateItemByUUID,
    moveItemByUUID,
  };

  return <CollectionContext.Provider value={value} {...props} />;
};

export const useCollectionContext = () => {
  return useContext<CollectionContextValue>(CollectionContext);
};
