import React, { useMemo, useState } from "react";
import { Button, Checkbox, Form, Input, Image } from "antd";
import { useMeshMaterials } from "../hooks";
import { MeshMaterialModelType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";

interface MeshMaterialViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const MeshMaterialView = ({
  selectedItem,
  children,
}: MeshMaterialViewProps) => {
  console.log("Selected Item in MeshMaterialView:", selectedItem);
  //console.log("Price Mod of Selected Item:", selectedItem?.item?.price_mod);
  const { updateMeshMaterial } = useMeshMaterials();
  const { setIsDirty } = useUIContext();
  const {
    data: { part },
  } = selectedItem;
  const meshMaterial = selectedItem.item as MeshMaterialModelType;

  const onFinish = async (values: any) => {
    if (part) {
      await updateMeshMaterial(part, {
        _uuid: meshMaterial._uuid,
        id: meshMaterial.id,
        name: values.name,
        price_mod: values.price_mod,
        visible: values.visible,
      });
      setIsDirty(false);
    }
  };

  const materialAssociations =
    meshMaterial.material_associations &&
    meshMaterial.material_associations.length > 0
      ? meshMaterial.material_associations[0]
      : { id: [], type: [], ui_message: [] };

  const categories =
    meshMaterial.material_category_associations &&
    meshMaterial.material_category_associations.length > 0
      ? meshMaterial.material_category_associations[0]
      : { name: [] };

  return (
    <div className="mesh-material-view">
      <Form
        name="material-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: meshMaterial.name,
          libraryPrice: meshMaterial.price_mod || 0,
          price_mod: meshMaterial.materialPriceModOverrideDefault || 0,
          visible: meshMaterial.visible,
          material_association_group_id: materialAssociations.id,
          material_association_type: materialAssociations.type,
          material_association_ui_message: materialAssociations.ui_message,
          material_category_name: categories.name,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          //rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Material Library Default Price"
          name="libraryPrice"
          //rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input disabled addonBefore="$" />
        </Form.Item>

        <Form.Item
          label="Additional Material Price Modifer"
          //tooltip={`Default Library Price $${meshMaterial.price_mod || 0}`}
          name="price_mod"
          rules={[
            { required: true, message: "Please input the material price!" },
          ]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        <Form.Item name="visible" valuePropName="checked">
          <Checkbox>Is visible?</Checkbox>
        </Form.Item>

        <Form.Item label="Material Category" name="material_category_name">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Material Image">
          <Image src={meshMaterial.imageUrl} />
        </Form.Item>

        <Form.Item
          label="Material Association Group ID"
          name="material_association_group_id"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Material Association Type"
          name="material_association_type"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Material Association UI Message"
          name="material_association_ui_message"
        >
          <Input disabled />
        </Form.Item>

        {children}
      </Form>
    </div>
  );
};
