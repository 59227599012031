import axios from "axios";
import { BACKEND_URL, GET_METHOD, POST_METHOD } from "sharedConstants";

const getShell = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shells/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};
const getAllShells = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shells`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};
const getAllShellStatuses = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shell_statuses`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const makeMasterShellVersion = async function ({ shell_id, shell_version_id }) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/make_master_shell_version`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      shell_id,
      shell_version_id,
    },
  });
  return response;
};

const createShellVersion = async function ({
  shell_id,
  copy_from_shell_version_id,
}) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/create_shell_version`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      shell_id,
      copy_from_shell_version_id,
    },
  });
  return response;
};

const associateShellVersionToShowroom = async function ({
  showroomId,
  shellVersionId,
  data: { name },
}) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/showroom_shell_admin/${showroomId}/${shellVersionId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      name,
    },
  });
  return response;
};

export {
  getShell,
  getAllShells,
  getAllShellStatuses,
  makeMasterShellVersion,
  createShellVersion,
  associateShellVersionToShowroom,
};
